var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('a-card',{attrs:{"body-style":{ padding: '24px 32px' },"bordered":false}},[_c('a-form-item',{attrs:{"label":_vm.$t('field.warehouseCode'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'warehouseData.warehouseCode',
            {
              rules: ([
                { required: true, message: '请输入' + this.$t('field.warehouseCode') },
                { max: 100, message: this.$t('field.warehouseCode') + '长度小于等于100' },
                {type:'checkExists', existIdValue:_vm.warehouseData.warehouseId, checkField:'warehouseCode', idFieldName: 'warehouseId',tableName:'warehouse' }
              ]),
            } ]),expression:"[\n            'warehouseData.warehouseCode',\n            {\n              rules: ([\n                { required: true, message: '请输入' + this.$t('field.warehouseCode') },\n                { max: 100, message: this.$t('field.warehouseCode') + '长度小于等于100' },\n                {type:'checkExists', existIdValue:warehouseData.warehouseId, checkField:'warehouseCode', idFieldName: 'warehouseId',tableName:'warehouse' }\n              ]),\n            },\n          ]"}],attrs:{"name":"warehouseData.warehouseCode","placeholder":'请输入' + this.$t('field.warehouseCode'),"maxLength":4},model:{value:(_vm.warehouseData.warehouseCode),callback:function ($$v) {_vm.$set(_vm.warehouseData, "warehouseCode", $$v)},expression:"warehouseData.warehouseCode"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.warehouseName'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'warehouseData.warehouseName',
            {
              rules: [
                { required: true, message: '请输入' + this.$t('field.warehouseName') },
                { max: 100, message: this.$t('field.warehouseName') + '长度小于等于100' } ],
            } ]),expression:"[\n            'warehouseData.warehouseName',\n            {\n              rules: [\n                { required: true, message: '请输入' + this.$t('field.warehouseName') },\n                { max: 100, message: this.$t('field.warehouseName') + '长度小于等于100' },\n              ],\n            },\n          ]"}],attrs:{"name":"warehouseData.warehouseName","placeholder":'请输入' + this.$t('field.warehouseName')},model:{value:(_vm.warehouseData.warehouseName),callback:function ($$v) {_vm.$set(_vm.warehouseData, "warehouseName", $$v)},expression:"warehouseData.warehouseName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.warehouseAddress'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'warehouseData.warehouseAddress',
            {
              rules: [
                { required: true, message: '请输入' + this.$t('field.warehouseAddress') },
                { max: 100, message: this.$t('field.warehouseAddress') + '长度小于等于100' } ],
            } ]),expression:"[\n            'warehouseData.warehouseAddress',\n            {\n              rules: [\n                { required: true, message: '请输入' + this.$t('field.warehouseAddress') },\n                { max: 100, message: this.$t('field.warehouseAddress') + '长度小于等于100' },\n              ],\n            },\n          ]"}],attrs:{"name":"warehouseData.warehouseAddress","placeholder":'请输入' + this.$t('field.warehouseAddress')},model:{value:(_vm.warehouseData.warehouseAddress),callback:function ($$v) {_vm.$set(_vm.warehouseData, "warehouseAddress", $$v)},expression:"warehouseData.warehouseAddress"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.warehouseContactName'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'warehouseData.warehouseContactName',
            {
              rules: [
                { required: true, message: '请输入' + this.$t('field.warehouseContactName') },
                { max: 100, message: this.$t('field.warehouseContactName') + '长度小于等于100' } ],
            } ]),expression:"[\n            'warehouseData.warehouseContactName',\n            {\n              rules: [\n                { required: true, message: '请输入' + this.$t('field.warehouseContactName') },\n                { max: 100, message: this.$t('field.warehouseContactName') + '长度小于等于100' },\n              ],\n            },\n          ]"}],attrs:{"name":"warehouseData.warehouseContactName","placeholder":'请输入' + this.$t('field.warehouseContactName')},model:{value:(_vm.warehouseData.warehouseContactName),callback:function ($$v) {_vm.$set(_vm.warehouseData, "warehouseContactName", $$v)},expression:"warehouseData.warehouseContactName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.warehouseContactPhone'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'warehouseData.warehouseContactPhone',
            {
              rules: [
                { required: true, message: '请输入' + this.$t('field.warehouseContactPhone') },
                { max: 100, message: this.$t('field.warehouseContactPhone') + '长度小于等于100' } ],
            } ]),expression:"[\n            'warehouseData.warehouseContactPhone',\n            {\n              rules: [\n                { required: true, message: '请输入' + this.$t('field.warehouseContactPhone') },\n                { max: 100, message: this.$t('field.warehouseContactPhone') + '长度小于等于100' },\n              ],\n            },\n          ]"}],attrs:{"name":"warehouseData.warehouseContactPhone","placeholder":'请输入' + this.$t('field.warehouseContactPhone')},model:{value:(_vm.warehouseData.warehouseContactPhone),callback:function ($$v) {_vm.$set(_vm.warehouseData, "warehouseContactPhone", $$v)},expression:"warehouseData.warehouseContactPhone"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.city'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-cascader',{staticStyle:{"width":"100%"},attrs:{"options":_vm.codeList.cityList,"placeholder":"Please select","name":"city"},on:{"change":_vm.onChange},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }