import request from '@/utils/request'
import qs from 'qs'

const baseUrl = '/api'

export function saveWarehouseData (parameter) {
  let url = baseUrl + '/system/warehouse/save'
  if (!parameter.warehouseId) {
    url = baseUrl + '/system/warehouse/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchWarehouse (parameter) {
  return request({
    url: baseUrl + '/system/warehouse/search?' + qs.stringify(parameter),
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}

export function listWarehouse (parameter) {
  return request({
    url: baseUrl + '/system/warehouse/list',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data
    }
  })
}

export function getWarehouseData (parameter) {
  return request({
    url: baseUrl + '/system/warehouse/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function enableWarehouse (parameter) {
  return request({
    url: baseUrl + '/system/warehouse/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableWarehouse (parameter) {
  return request({
    url: baseUrl + '/system/warehouse/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function deleteWarehouse (parameter) {
  return request({
    url: baseUrl + '/system/warehouse/delete',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
