<template>
  <page-header-wrapper>
    <div class="a-card">
      <WarehouseSearch
        @resetData="resetSearchForm"
        :queryParam="queryParam"
        @handleAdd="handleAdd"/>
      <div class="table-operator">

        <a-button type="dashed" @click="tableOption">{{ optionAlertShow && '关闭' || '开启' }} alert</a-button>
        <!-- <a-dropdown v-if="selectedRowKeys.length > 0">
          <a href="#">操作</a>
          <a-menu slot="overlay">
            <a-menu-item class="hide" key="1" @click="handleDelete()">
              <a-icon type="delete"/>
              删除
            </a-menu-item>
            <a-menu-item class="hide" key="2">
              <a-icon type="down"/>
              批量操作
            </a-menu-item>
          </a-menu>
        </a-dropdown> -->
      </div>
      <s-table
        :bordered="true"
        ref="table"
        :columns="columns"
        :dataSource="data"
        :data="loadData"
        :alert="options.alert"
        :rowSelection="options.rowSelection"
        :pagination="{ pageSize: 10,pageNo:1}"
        rowKey="warehouseId"
        :scroll="{ x: true,}">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-dropdown>
            <a class="ant-dropdown-link">
              更多 <a-icon type="down"/>
            </a>
            <a-menu slot="overlay">
              <a-menu-item v-if="record.status===1">
                <a @click="handleDisable(record)">{{ $t('button.disable') }}</a>
              </a-menu-item>
              <a-menu-item v-if="record.status===0">
                <a @click="handleEnable(record)">{{ $t('button.enable') }}</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
        <div slot="creatorInfo" slot-scope="text,record">
          {{ record.creatorName }}<br>
          {{ formatDateTime(record.createTime) }}
        </div>
        <div slot="updaterInfo" slot-scope="text,record">
          {{ record.updaterName }}<br>
          {{ formatDateTime(record.updateTime) }}
        </div>
      </s-table>
    </div>

    <a-modal
      width="50%"
      :title="$t('page.warehouse')"
      :visible="warehouseModalShow"
      :confirm-loading="confirmEditLoading"
      @ok="handleEditOk"
      @cancel="handleEditCancel"
    >
      <WarehouseEdit :warehouseData="warehouseData" @cityValChange="cityValChange"/>
    </a-modal>
    <CommentEditor ref="commentEditor" @handleOk="handleCommentEditOk"/>
  </page-header-wrapper></template>

<script>
// 演示如何使用 this.$dialog 封装 modal 组件
import {
  deleteWarehouse,
  disableWarehouse,
  enableWarehouse,
  saveWarehouseData,
  searchWarehouse
} from '@/api/system/warehouse'
import moment from 'moment'
import { STable } from '@/components'

import WarehouseSearch from '@/views/system/WarehouseSearch'
import WarehouseEdit from '@/views/system/components/WarehouseEdit'
import CommentEditor from '@/components/comment/CommentEditor'

export default {
  name: 'WarehouseList',
  components: {
    CommentEditor, STable, WarehouseEdit, WarehouseSearch
  },
  data () {
    return { confirmEditLoading: false,
      // 查询参数
      queryParam: {},
      data: [],
      status: 'all',
      columns: [
        {
          'defaultTitle': '操作',
          'title': '操作',
          'dataIndex': 'action',
          'key': 'action',
          'align': 'left',
          'scopedSlots': {
            'customRender': 'action'
          },
          wdith: 100
        },
        {
          'defaultTitle': '仓库编码',
          'title': this.$t('columnName.warehouseCode'),
          'dataIndex': 'warehouseCode',
          'key': 'warehouseCode',
          'align': 'left'
        },
        {
          'defaultTitle': '仓库名称',
          'title': this.$t('columnName.warehouseName'),
          'dataIndex': 'warehouseName',
          'key': 'warehouseName',
          'align': 'left'
        },
        {
          'defaultTitle': '仓库地址',
          'title': this.$t('columnName.warehouseAddress'),
          'dataIndex': 'warehouseAddress',
          'key': 'warehouseAddress',
          'align': 'left'
        },
        {
          'defaultTitle': '仓库联系人',
          'title': this.$t('columnName.warehouseContactName'),
          'dataIndex': 'warehouseContactName',
          'key': 'warehouseContactName',
          'align': 'left'
        },
        {
          'defaultTitle': '仓库联系人电话',
          'title': this.$t('columnName.warehouseContactPhone'),
          'dataIndex': 'warehouseContactPhone',
          'key': 'warehouseContactPhone',
          'align': 'left'
        },
        {
          'defaultTitle': '国家',
          'title': this.$t('columnName.country'),
          'dataIndex': 'country',
          'key': 'country',
          'align': 'left'
        },
        {
          'defaultTitle': '州/省',
          'title': this.$t('columnName.state'),
          'dataIndex': 'state',
          'key': 'state',
          'align': 'left'
        },
        {
          'defaultTitle': '城市',
          'title': this.$t('columnName.city'),
          'dataIndex': 'city',
          'key': 'city',
          'align': 'left'
        },
        {
          'defaultTitle': '状态',
          'title': this.$t('columnName.status'),
          'dataIndex': 'status',
          'key': 'status',
          'align': 'center',
          customRender: (text) => (this.getCode('enableStatus', text))
        },
        {
          'defaultTitle': '创建人',
          'title': this.$t('columnName.creatorName'),
          'dataIndex': 'creatorName',
          'key': 'creatorName',
          'align': 'left',
          ellipsis: true,
          'scopedSlots': {
            'customRender': 'creatorInfo'
          }
        },
        {
          'defaultTitle': '更新人',
          'title': this.$t('columnName.updaterName'),
          'dataIndex': 'updaterName',
          'key': 'updaterName',
          'align': 'left',
          ellipsis: true,
          'scopedSlots': {
            'customRender': 'updaterInfo'
          }
        }

      ],
      // 加载数据方法 必须为 Promise 对象
      // loadData: parameter => {
      //   console.log('loadData.parameter', parameter)
      //   return getWarehouse(Object.assign(parameter, this.queryParam))
      //       .then(res => {
      //         return res.result
      //       })
      // },
      selectedRowKeys: [],
      selectedRows: [],
      warehouseModalShow: false,
      warehouseData: {},
      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    // getRoleList({t: new Date()})
  },
  mounted () {

  },
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData (parameter) {
      return searchWarehouse(Object.assign(parameter, this.queryParam))
        .then(res => {
          console.log('loadData->getWarehouseList.parameter', parameter, res)
          return res
        })
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },

    handleAdd (record) {
      this.warehouseData = {}
      // 弹出编辑页面
      this.warehouseModalShow = true
    },

    handleEdit (record) {
      this.warehouseData = record
      // 弹出编辑页面
      this.warehouseModalShow = true
    },
    handleCommentEditOk (params) {
      const record = params.record
      const that = this
      if (params.type === 'disable') {
        return disableWarehouse(record)
          .then(res => {
            console.log('handleDisable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('锁定成功')
            return res
          }).catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      } else if (params.type === 'enable') {
        return enableWarehouse(record)
          .then(res => {
            console.log('handleEnable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('解锁定成功')
            return res
          }).catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      }
    },
    handleDisable (record) {
      this.$refs['commentEditor'].show(record, 'disable')
    },
    handleEnable (record) {
      this.$refs['commentEditor'].show(record, 'enable')
    },
    handleEditCancel (record) {
      // 弹出编辑页面
      this.warehouseData = {}
      this.warehouseModalShow = false
    },
    handleEditOk (record) {
      var that = this
      console.log(this.warehouseData)
      saveWarehouseData(this.warehouseData).then(res => {
        that.$message.success(this.$t('save.entity.warehouse.success'))
        this.$refs.table.refresh(true)
      }).catch(res => {
          that.$message.success(this.$t('save.entity.warehouse.fail'))
      })
      this.warehouseData = {}
      // 弹出编辑页面
      this.warehouseModalShow = false
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    resetSearchForm (data) {
       this.queryParam = data
      this.$refs.table.refresh(true)
    },
    handleDelete () {
      const that = this
      const params = {
        warehouseId: this.selectedRows[0].warehouseId
      }
      deleteWarehouse(params)
      .then((res) => {
        that.$message.success(this.$t('save.entity.afterSaleService.success'))
        this.$refs.table.refresh(true)
      })
      .catch((res) => {
        that.$message.success(this.$t('save.entity.afterSaleService.fail'))
      })
    },
    // 子组件传来的事件
    cityValChange (val) {
       console.log(this.warehouseData)
      this.warehouseData.country = val[0]
      this.warehouseData.state = val[1]
      this.warehouseData.city = val[2]
    }
  }
}
</script>
<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, .45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;

  span {
    line-height: 20px;
  }

  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
