<template>
  <div>
    <!-- hidden PageHeaderWrapper title demo -->
    <div>
      <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
        <a-form-item
          :label="$t('field.warehouseCode')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'warehouseData.warehouseCode',
              {
                rules: ([
                  { required: true, message: '请输入' + this.$t('field.warehouseCode') },
                  { max: 100, message: this.$t('field.warehouseCode') + '长度小于等于100' },
                  {type:'checkExists', existIdValue:warehouseData.warehouseId, checkField:'warehouseCode', idFieldName: 'warehouseId',tableName:'warehouse' }
                ]),
              },
            ]"
            name="warehouseData.warehouseCode"
            v-model="warehouseData.warehouseCode"
            :placeholder="'请输入' + this.$t('field.warehouseCode')"
            :maxLength="4"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.warehouseName')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'warehouseData.warehouseName',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.warehouseName') },
                  { max: 100, message: this.$t('field.warehouseName') + '长度小于等于100' },
                ],
              },
            ]"
            name="warehouseData.warehouseName"
            v-model="warehouseData.warehouseName"
            :placeholder="'请输入' + this.$t('field.warehouseName')"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.warehouseAddress')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'warehouseData.warehouseAddress',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.warehouseAddress') },
                  { max: 100, message: this.$t('field.warehouseAddress') + '长度小于等于100' },
                ],
              },
            ]"
            name="warehouseData.warehouseAddress"
            v-model="warehouseData.warehouseAddress"
            :placeholder="'请输入' + this.$t('field.warehouseAddress')"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.warehouseContactName')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'warehouseData.warehouseContactName',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.warehouseContactName') },
                  { max: 100, message: this.$t('field.warehouseContactName') + '长度小于等于100' },
                ],
              },
            ]"
            name="warehouseData.warehouseContactName"
            v-model="warehouseData.warehouseContactName"
            :placeholder="'请输入' + this.$t('field.warehouseContactName')"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.warehouseContactPhone')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'warehouseData.warehouseContactPhone',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.warehouseContactPhone') },
                  { max: 100, message: this.$t('field.warehouseContactPhone') + '长度小于等于100' },
                ],
              },
            ]"
            name="warehouseData.warehouseContactPhone"
            v-model="warehouseData.warehouseContactPhone"
            :placeholder="'请输入' + this.$t('field.warehouseContactPhone')"
          />
        </a-form-item>
        <!-- <a-form-item
          :label="$t('field.country')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-select mode="default" v-model="warehouseData.country" style="width: 400px">
            <a-select-option
              v-for="(item, index) in codeList.country"
              :key="index"
              :value="item.value"
            >{{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          :label="$t('field.state')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'warehouseData.state',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.state') },
                  { max: 100, message: this.$t('field.state') + '长度小于等于100' },
                ],
              },
            ]"
            name="warehouseData.state"
            v-model="warehouseData.state"
            :placeholder="'请输入' + this.$t('field.state')"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.city')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'warehouseData.city',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.city') },
                  { max: 100, message: this.$t('field.city') + '长度小于等于100' },
                ],
              },
            ]"
            name="warehouseData.city"
            v-model="warehouseData.city"
            :placeholder="'请输入' + this.$t('field.city')"
          />
        </a-form-item> -->
        <a-form-item
          :label="$t('field.city')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-cascader
            :options="codeList.cityList"
            v-model="city"
            placeholder="Please select"
            @change="onChange"
            name="city"
            style="width:100%"/>
        </a-form-item>
      </a-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditWarehouse',
  props: {
    warehouseData: {
      type: Object
    }
  },
  watch: {
    warehouseData (val) {
      console.log(val)
      this.city = [val.country, val.state, val.city]
    }
  },
  mounted () {
    this.city = [this.warehouseData.country, this.warehouseData.state, this.warehouseData.city]
  },
  data () {
    return {
      codeList: {
        country: [{ label: '美国', value: 'USA' }, { label: '墨西哥', value: 'Maxico' }, { label: '巴西', value: 'Brazil' }],
        isValid: []
      },
      city: []
    }
  },
  methods: {
    onChange (value) {
      console.log(value)
      this.$emit('cityValChange', value)
    }
  }
}
</script>
<style>
</style>
