<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.warehouseCode')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-input
                v-decorator="[
                  'queryParam.warehouseCode',
                  {
                    rules: [
                      { required: true, message: '请输入' + this.$t('field.warehouseCode') },
                      { max: 100, message: this.$t('field.warehouseCode') + '长度小于等于100' },
                    ],
                  },
                ]"
                name="queryParam.warehouseCode"
                v-model="queryParam.warehouseName"
                :placeholder="'请输入' + this.$t('field.warehouseCode')"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.warehouseName')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-input
                v-decorator="[
                  'queryParam.warehouseName',
                  {
                    rules: [
                      { required: true, message: '请输入' + this.$t('field.warehouseName') },
                      { max: 100, message: this.$t('field.warehouseName') + '长度小于等于100' },
                    ],
                  },
                ]"
                name="queryParam.warehouseName"
                v-model="queryParam.warehouseName"
                :placeholder="'请输入' + this.$t('field.warehouseName')"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.warehouseAddress')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-input
                v-decorator="[
                  'queryParam.warehouseAddress',
                  {
                    rules: [
                      { required: true, message: '请输入' + this.$t('field.warehouseAddress') },
                      { max: 100, message: this.$t('field.warehouseAddress') + '长度小于等于100' },
                    ],
                  },
                ]"
                name="queryParam.warehouseAddress"
                v-model="queryParam.warehouseAddress"
                :placeholder="'请输入' + this.$t('field.warehouseAddress')"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" v-if="showAdvancedSearchCondition">
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.warehouseContactName')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-input
                v-decorator="[
                  'queryParam.warehouseContactName',
                  {
                    rules: [
                      { required: true, message: '请输入' + this.$t('field.warehouseContactName') },
                      { max: 100, message: this.$t('field.warehouseContactName') + '长度小于等于100' },
                    ],
                  },
                ]"
                name="queryParam.warehouseContactName"
                v-model="queryParam.warehouseContactName"
                :placeholder="'请输入' + this.$t('field.warehouseContactName')"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.warehouseContactPhone')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-input
                v-decorator="[
                  'queryParam.warehouseContactPhone',
                  {
                    rules: [
                      { required: true, message: '请输入' + this.$t('field.warehouseContactPhone') },
                      { max: 100, message: this.$t('field.warehouseContactPhone') + '长度小于等于100' },
                    ],
                  },
                ]"
                name="queryParam.warehouseContactPhone"
                v-model="queryParam.warehouseContactPhone"
                :placeholder="'请输入' + this.$t('field.warehouseContactPhone')"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.country')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-input
                v-decorator="[
                  'queryParam.country',
                  {
                    rules: [
                      { required: true, message: '请输入' + this.$t('field.country') },
                      { max: 100, message: this.$t('field.country') + '长度小于等于100' },
                    ],
                  },
                ]"
                name="queryParam.country"
                v-model="queryParam.country"
                :placeholder="'请输入' + this.$t('field.country')"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" v-if="showAdvancedSearchCondition">
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.state')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-input
                v-decorator="[
                  'queryParam.state',
                  {
                    rules: [
                      { required: true, message: '请输入' + this.$t('field.state') },
                      { max: 100, message: this.$t('field.state') + '长度小于等于100' },
                    ],
                  },
                ]"
                name="queryParam.state"
                v-model="queryParam.state"
                :placeholder="'请输入' + this.$t('field.state')"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.city')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-input
                v-decorator="[
                  'queryParam.city',
                  {
                    rules: [
                      { required: true, message: '请输入' + this.$t('field.city') },
                      { max: 100, message: this.$t('field.city') + '长度小于等于100' },
                    ],
                  },
                ]"
                name="queryParam.city"
                v-model="queryParam.city"
                :placeholder="'请输入' + this.$t('field.city')"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.status')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
              :required="false"
            >
              <a-select :allowClear="true" mode="default" v-model="queryParam.status">
                <a-select-option
                  v-for="(item, index) in codeList.status"
                  :key="index"
                  :value="item.value"
                >{{ item.label }}
                </a-select-option>
              </a-select></a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="24" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="(showAdvancedSearchCondition && { overflow: 'hidden' }) || {}"
            >
              <a-button icon="plus" type="primary" @click="$emit('handleAdd')">{{ $t('button.create') }}</a-button>
              <a-divider type="vertical" />
              <a-button icon="search" type="primary" @click="queryData">{{
                $t('button.search')
              }}</a-button
              ><a-divider type="vertical" />
              <a-button style="margin-left: 8px" @click="resetData">{{ $t('button.reset') }}</a-button>
              <a @click="toggleAdvancedSearch" style="margin-left: 8px">
                {{ showAdvancedSearchCondition ? $t('button.collapseIn') : $t('button.collapseOut') }}
                <a-icon :type="showAdvancedSearchCondition ? 'up' : 'down'" />
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'WarehouseSearch',
  components: {},
  props: {
    // 查询参数
    // queryParam: {
    //   type: Object,
    // },
  },
  data () {
    return {
      codeList: {
        warehouseCode: [],
        country: []
      },
      showAdvancedSearchCondition: false,
      queryParam: {}
    }
  },
  created () {},
  mounted () {},
  methods: {
    toggleAdvancedSearch () {
      this.showAdvancedSearchCondition = !this.showAdvancedSearchCondition
    },
    resetData () {
      this.$emit('resetData', this.queryParam)
    },
    queryData () {
      this.$emit('resetData', this.queryParam)
    }
  }
}
</script>
